import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { IconsList } from '../../components/icons-list';
import { P } from '../../components/Typography';
export const pageQuery = graphql`
  {
    mdx(fields: {slug: {eq: "/icons/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <contentwrapper>
      <content>
        <h1>{props.pageContext.frontmatter.title}</h1>
        <P variant="lead" mdxType="P">UI, Twilio Product, and Logo icons that are available in the Paste icon system.</P>
        <p>{`When using any of these icons be sure to follow the `}<a parentName="p" {...{
            "href": "/icons/usage-guidelines"
          }}>{`usage guidelines`}</a>{` carefully and don't forget to read the `}<a parentName="p" {...{
            "href": "/getting-started/engineering"
          }}>{`engineering getting started`}</a>{` page to ensure you have the correct dependencies when adding icons to your application.`}</p>
      </content>
    </contentwrapper>
    <IconsList mdxType="IconsList" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      